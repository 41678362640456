import data from "./currencies.json";

export default class Currency {
  name: string;
  code: string;
  minorUnits: number;

  constructor(name: string, code: string, minorUnits: number) {
    this.name = name;
    this.code = code;
    this.minorUnits = minorUnits;
  }

  static fromString(value: string): Currency {
    return lookupCurrency(value);
  }

  equals(y: Currency): boolean {
    return this.toString() === y.toString();
  }

  toString(): string {
    return this.code;
  }

  toJSON(): string {
    return this.toString();
  }

  clone(): Currency {
    return new Currency(this.name, this.code, this.minorUnits);
  }
}

function lookupCurrency(code: string): Currency {
  const c = currencies[code];
  if (c == null) {
    throw new Error(`unknown currency code '${code}'`);
  }
  return c;
}

export const currencies: Record<string, Currency> = {};
data.map((c) => {
  currencies[c.code] = new Currency(c.name, c.code, c.minorUnits);
});
