import { createPinia, defineStore } from "pinia";
import { auth, signInWithEmailAndPassword } from "@/firebase";
import firebase from "firebase/app"; // TODO avoid this. Edit: why?
import api, {
  User,
  Sheet,
  Expense,
  CreateItemRequest,
  UpdateItemRequest,
  CreateMemberRequest,
  UpdateMemberRequest,
  Member,
  CreateUserRequest,
  CreateExpenseRequest,
  UpdateExpenseRequest,
  UpdateSheetRequest,
  Item,
  DeleteSheetRequest,
  DeleteItemRequest,
  DeleteExpenseRequest, DeleteMemberRequest
} from "@/api";
import { recalculateBalances } from "@/stores/plugins";
import { Balance } from "@/stores/plugins/balances";
import Currency from "@/decimal/currency";

const pinia = createPinia();

pinia.use(recalculateBalances);

export interface State {
  user: User | null;
  sheets: Record<string, Sheet>;
  sheetBalances: Record<string, Record<string, Balance>>; // Sheet ID -> Member ID -> Balance

  // TODO: move this into a component:
  itemIdMap: Record<string, Record<string, string>>; // Sheet ID -> Item ID -> Idempotency key
}

const useStore = defineStore("main", {
  state: (): State => ({
    user: null,
    sheets: {},
    sheetBalances: {},
    itemIdMap: {},
  }),

  actions: {
    setUser(user: User) {
      this.user = user;
    },

    setSheet(sheet: Sheet) {
      // Sometimes the API responses don't include the expenses
      // in which case we shouldn't overwrite what we already have.
      if (sheet.expenses == null) {
        sheet.expenses = this.sheets[sheet.id]?.expenses;
      }

      this.sheets[sheet.id] = sheet;
    },

    setSheets(sheets: Sheet[]) {
      for (const sheet of sheets) {
        this.sheets[sheet.id] = sheet;
      }
    },

    removeSheet(sheetId: string) {
      delete this.sheets[sheetId];
    },

    setMember(member: Member) {
      const sh = this.sheets[member.sheetId];
      if (sh == null) {
        throw new Error("sheet not found");
      }

      const i = sh.members.findIndex((m) => m.id === member.id);
      if (i < 0) {
        sh.members.push(member);
      } else {
        sh.members[i] = member;
      }
    },

    removeMember(sheetId: string, memberId: string) {
      const sh = this.sheets[sheetId];
      if (sh == null) {
        throw new Error("sheet not found");
      }

      // Keep all members except the one with the given ID
      sh.members = sh.members.filter(m => m.id !== memberId);
    },

    setExpense(expense: Expense) {
      const sh = this.sheets[expense.sheetId];
      if (sh == null) {
        throw new Error("sheet not found");
      }

      // Make sure the array is not undefined
      if (sh.expenses == null) {
        sh.expenses = [];
      }

      const i = sh.expenses.findIndex((ex) => ex.id === expense.id);
      if (i < 0) {
        sh.expenses.push(expense);
      } else {
        sh.expenses[i] = expense;
      }
    },

    removeExpense(sheetId: string, expenseId: string) {
      const sh = this.sheets[sheetId];
      if (sh == null) {
        throw new Error("sheet not found");
      }

      // If there are no expenses then I guess there's no need to remove this one
      if (sh.expenses == null) {
        return;
      }

      const i = sh.expenses.findIndex((ex) => ex.id === expenseId);
      if (i < 0) {
        return; // It already doesn't exist
      }

      // Delete the expense from the array
      sh.expenses.splice(i, 1);
    },

    setItem({
      sheetId,
      expenseId,
      item,
    }: {
      sheetId: string;
      expenseId: string;
      item: Item;
    }) {
      const sh = this.sheets[sheetId];
      if (sh == null) {
        throw new Error("sheet not found");
      }

      const ex = sh.expenses?.find((e) => e.id === expenseId);
      if (ex == null) {
        throw new Error("expense not found");
      }

      const existingIndex = ex.items.findIndex((i) => i.id === item.id);

      if (existingIndex < 0) {
        ex.items.push(item);
      } else {
        ex.items[existingIndex] = item;
      }
    },

    removeItem({
      sheetId,
      expenseId,
      itemId,
    }: {
      sheetId: string;
      expenseId: string;
      itemId: string;
    }) {
      const sh = this.sheets[sheetId];
      if (sh == null) {
        throw new Error("sheet not found");
      }

      const ex = sh.expenses?.find((e) => e.id === expenseId);
      if (ex == null) {
        throw new Error("expense not found");
      }

      const i = ex.items.findIndex((i) => i.id === itemId);

      if (i < 0) {
        return; // It already doesn't exist
      }

      // Delete the item from the array
      ex.items.splice(i, 1);
    },

    setItemIdMap({
      sheetId,
      itemId,
      idempotencyKey,
    }: {
      sheetId: string;
      itemId: string;
      idempotencyKey: string;
    }) {
      if (this.itemIdMap[sheetId] == null) {
        this.itemIdMap[sheetId] = {};
      }

      this.itemIdMap[sheetId][itemId] = idempotencyKey;
    },

    setSheetBalances({
      sheetId,
      balances,
    }: {
      sheetId: string;
      balances: Record<string, Balance>;
    }): void {
      this.sheetBalances[sheetId] = balances;
    },

    // async refreshApiToken() {
    //   if (auth.currentUser == null) {
    //     throw new Error("User is not signed in");
    //   }
    //
    //   const token = await auth.currentUser.getIdToken(true);
    //   this.setApiToken(token);
    // },

    async login(form: { email: string; password: string }) {
        await auth.setPersistence(firebase.auth.Auth.Persistence.LOCAL);

        const { user } = await signInWithEmailAndPassword(
          form.email,
          form.password
        );
    },

    async logout() {
      await auth.signOut();
    },

    async signup(form: { email: string; password: string }) {
      const { user } = await auth.createUserWithEmailAndPassword(
        form.email,
        form.password
      );
    },

    async sendVerificationEmail() {
      if (auth.currentUser == null) {
        throw new Error("User is not signed in");
      }

      await auth.currentUser.sendEmailVerification();
    },

    // ----- User -----

    async createUser({ name }: { name: string }) {
      if (auth.currentUser == null) {
        throw new Error("User is not signed in");
      }

      const rsp = await api.createUser({
        id: auth.currentUser.uid,
        name: name,
      });

      // Refresh the token
      // await auth.currentUser.getIdToken(true);

      this.setUser(rsp.user);
    },

    async readUser(userId: string) {
      const rsp = await api.readUser({ id: userId });
      this.setUser(rsp.user);
    },

    // ----- Sheet -----

    async createSheet(form: {
      idempotencyKey: string;
      name: string;
      currency: Currency;
      public: boolean;
    }): Promise<Sheet> {
      if (auth.currentUser == null) {
        throw new Error("User is not signed in");
      }

      const rsp = await api.createSheet({
        idempotencyKey: form.idempotencyKey,
        name: form.name,
        currency: form.currency.toString(),
        userId: auth.currentUser.uid,
        public: form.public,
      });

      return rsp.sheet;
    },

    async readSheet(sheetId: string) {
      const rsp = await api.readSheet({
        sheetId: sheetId,
        includeExpenses: true,
      });

      this.setSheet(rsp.sheet);
    },

    async listSheets() {
      if (auth.currentUser == null) {
        throw new Error("User is not signed in");
      }

      const rsp = await api.listSheets({
        userId: auth.currentUser.uid,
        affiliation: "owner",
      });

      this.setSheets(rsp.sheets);
    },

    async updateSheet(req: UpdateSheetRequest) {
      const rsp = await api.updateSheet(req);
      this.setSheet(rsp.sheet);
    },

    async deleteSheet(req: DeleteSheetRequest) {
      await api.deleteSheet(req);
      this.removeSheet(req.sheetId);
    },

    // ----- Member -----

    async createMember(req: CreateMemberRequest) {
      const rsp = await api.createMember(req);
      this.setMember(rsp.member);
    },

    async updateMember(req: UpdateMemberRequest) {
      const rsp = await api.updateMember(req);
      this.setMember(rsp.member);
    },

    async deleteMember(req: DeleteMemberRequest) {
      const rsp = await api.deleteMember(req);
      this.removeMember(req.sheetId, req.memberId);
    },

    // ----- Expense -----

    async createExpense(req: CreateExpenseRequest) {
      const rsp = await api.createExpense(req);
      this.setExpense(rsp.expense);
    },

    async updateExpense(req: UpdateExpenseRequest) {
      const rsp = await api.updateExpense(req);
      this.setExpense(rsp.expense);
    },

    async deleteExpense(req: DeleteExpenseRequest) {
      await api.deleteExpense(req);
      this.removeExpense(req.sheetId, req.expenseId);
    },

    // ----- Item -----

    async createItem(req: CreateItemRequest) {
      const rsp = await api.createItem(req);

      this.setItem({
        sheetId: req.sheetId,
        expenseId: req.expenseId,
        item: rsp.item,
      });
      this.setItemIdMap({
        sheetId: req.sheetId,
        itemId: rsp.item.id,
        idempotencyKey: req.idempotencyKey,
      });
    },

    async updateItem(req: UpdateItemRequest) {
      const rsp = await api.updateItem(req);

      this.setItem({
        sheetId: req.sheetId,
        expenseId: req.expenseId,
        item: rsp.item,
      });
    },

    async deleteItem(req: DeleteItemRequest) {
      await api.deleteItem(req);
      this.removeItem({
        sheetId: req.sheetId,
        expenseId: req.expenseId,
        itemId: req.itemId,
      });
    },

    isAuthenticated() {
      return auth.currentUser != null;
    },

    async isEmailVerified() {
      if (auth.currentUser == null) {
        return false;
      }

      await auth.currentUser.reload();
      return auth.currentUser.emailVerified;
    },

    itemIdempotencyKey({
      sheetId,
      itemId,
    }: {
      sheetId: string;
      itemId: string;
    }) {
      const m = this.itemIdMap[sheetId];
      if (m == null) {
        return undefined;
      }
      return m[itemId] ?? undefined;
    },
  },
});

export { pinia, useStore };

// // The following exports will be used to enable types in the
// // implementation of actions and getters.
// export {
//   rootActionContext,
//   moduleActionContext,
//   rootGetterContext,
//   moduleGetterContext,
// };
//
// // The following lines enable types in the injected store '$store'.
// export type AppStore = typeof store;
// declare module "vuex" {
//   interface Store<S> {
//     direct: AppStore;
//   }
// }
