
import { defineComponent } from "vue";
// import { auth } from "@/firebase";
// import {errNotFound} from "@/api";

import Header from "@/components/layout/AppHeader.vue";
import Footer from "@/components/layout/AppFooter.vue";
import LoadingBanana from "@/components/ui/LoadingBanana.vue";
import { mapStores } from "pinia";
import { useStore } from "@/stores";

export default defineComponent({
  name: "App",

  components: { Header, Footer, LoadingBanana },

  data(): {
    loading: boolean;
    error: any;
  } {
    return {
      loading: false,
      error: false
    };
  },

  computed: {
    ...mapStores(useStore),
  },

  async created() {
    // const userId = auth.currentUser.uid;
    //
    // try {
    //   await this.$store.dispatch('readUser', userId)
    // } catch (err) {
    //   switch (err) {
    //     // No profile created yet
    //     case errNotFound:
    //       await this.$router.push({
    //         name: 'create_profile',
    //       });
    //       break;
    //     default:
    //       this.error = true;
    //   }
    // }
    //
    // this.loading = false;
  },
});
