import { createRouter, createWebHistory } from "vue-router";
import { useStore } from "@/stores";
import { auth } from "@/firebase";
import { errEmailNotVerified, errNotFound } from "@/api";

// Dynamic imports for all routes
// https://router.vuejs.org/guide/advanced/lazy-loading.html
// Nb: This means loading a new page requires a network request,
// and there's no easy way to display a loading state to the user.
// The area inside the router view is just blank until the page loads.
/* eslint-disable */
const ApiTokenPage = () => import("@/pages/ApiTokenPage.vue");
const CreateProfilePage = () => import("@/pages/CreateProfilePage.vue");
const CreateSheetPage = () => import("@/pages/CreateSheetPage.vue");
const DashboardPage = () => import("@/pages/DashboardPage.vue");
const HomePage = () => import("@/pages/HomePage.vue");
const LoginPage = () => import("@/pages/LoginPage.vue");
const LogoutPage = () => import("@/pages/LogoutPage.vue");
const PrivacyPolicyPage = () => import("@/pages/PrivacyPolicyPage.vue");
const SheetExpensesPage = () => import("@/pages/sheet/SheetExpensesPage.vue");
const SheetPage = () => import("@/pages/SheetPage.vue");
const SheetSettingsPage = () => import("@/pages/sheet/SheetSettingsPage.vue");
const SheetSummaryPage = () => import("@/pages/SheetSummaryPage.vue");
const SignupPage = () => import("@/pages/SignupPage.vue");
const VerifyPage = () => import("@/pages/VerifyEmailPage.vue");
const NewExpensePage = () => import("@/pages/sheet/NewExpensePage.vue");
const ListExpensesPage = () => import("@/pages/sheet/ListExpensesPage.vue");
const EditExpensePage = () => import("@/pages/sheet/EditExpensePage.vue");
const AddMemberPage = () => import("@/pages/sheet/settings/AddMemberPage.vue");
const GeneralSettingsPage = () => import("@/pages/sheet/settings/GeneralSettingsPage.vue");
const SheetSettingsMembersPage = () => import("@/pages/sheet/settings/SheetSettingsMembersPage.vue");
/* eslint-enable */

const routes = [
  {
    path: "/",
    name: "home",
    component: HomePage,
    meta: {
      allowUnauthenticated: true,
      allowNoProfile: true
    }
  },
  {
    path: "/privacy",
    name: "privacy_policy",
    component: PrivacyPolicyPage,
    meta: {
      allowUnauthenticated: true,
      allowNoProfile: true
    }
  },
  {
    path: "/login",
    name: "login",
    component: LoginPage,
    meta: {
      allowUnauthenticated: true,
      allowNoProfile: true
    }
  },
  {
    path: "/signup",
    name: "signup",
    component: SignupPage,
    meta: {
      allowUnauthenticated: true,
      allowNoProfile: true
    }
  },
  {
    path: "/verify",
    name: "verify",
    component: VerifyPage,
    meta: {
      allowUnverified: true,
      allowNoProfile: true
    }
  },
  {
    path: "/create-profile",
    name: "create_profile",
    component: CreateProfilePage,
    meta: {
      allowNoProfile: true
    }
  },
  {
    path: "/logout",
    name: "logout",
    component: LogoutPage,
    meta: {
      allowUnauthenticated: true,
      allowNoProfile: true
    }
  },
  {
    path: "/dashboard",
    name: "dashboard",
    component: DashboardPage,
    meta: {}
  },
  {
    path: "/api-token",
    name: "api_token",
    component: ApiTokenPage,
    meta: {}
  },
  {
    path: "/create-sheet",
    name: "create_sheet",
    component: CreateSheetPage
  },
  {
    path: "/sheet/:sheetId",
    name: "sheet",
    component: SheetPage,
    children: [
      {
        path: "summary",
        name: "sheet_summary",
        component: SheetSummaryPage,
        props: true,
        meta: {
          allowUnauthenticated: true
        }
      },
      {
        path: "expenses",
        name: "sheet_expenses",
        component: SheetExpensesPage,
        props: true,
        children: [
          {
            path: "",
            name: "list_expenses",
            component: ListExpensesPage,
            props: true,
            meta: {
              allowUnauthenticated: true
            }
          },
          {
            path: "new",
            name: "new_expense",
            component: NewExpensePage,
            props: true
          },
          {
            path: ":expenseId",
            name: "edit_expense",
            component: EditExpensePage,
            props: true
          }
        ]
      },

      {
        path: "settings",
        name: "sheet_settings",
        component: SheetSettingsPage,
        children: [
          {
            path: "",
            name: "sheet_settings_general",
            component: GeneralSettingsPage,
          },
          {
            path: "members",
            name: "sheet_settings_members",
            component: SheetSettingsMembersPage,
          },
          {
            path: "members/new",
            name: "sheet_settings_members_new",
            component: AddMemberPage,
          }
        ],
      }
    ]
  }
];

const router = createRouter({
  history: createWebHistory(),
  routes
});

router.beforeEach((to) => {
  if (to.meta.allowUnauthenticated) {
    return;
  }

  if (auth.currentUser == null) {
    return {
      name: "login",
      query: { destination: String(to.name) }
    };
  }

  if (to.meta.allowUnverified) {
    return;
  }

  if (!auth.currentUser.emailVerified) {
    return {
      name: "verify"
    };
  }

  // if (store.user != null || to.meta.allowNoProfile) {
  //   return;
  // }
  //
  // return {
  //   name: "create_profile"
  // };

  return;
});

router.afterEach(async (to) => {
  console.debug("router: running afterEach hook");

  // This case should have been handled correctly by the beforeEach hook
  if (auth.currentUser == null) {
    console.debug("router: current user is null");
    return;
  }

  if (to.meta.allowNoProfile) {
    console.debug("router: page allows no profile");
    return;
  }

  const store = useStore();

  if (store.user !== null) {
    console.debug("router: a user profile already exists in the store");
    return;
  }

  // If we don't have a user, issue a read request and wait.
  // This all happens in the afterEach hook so we don't block
  // the rest of the page loading.
  try {
    console.debug("router: awaiting readUser response");
    await store.readUser(auth.currentUser.uid);
  } catch (err) {
    switch (err) {
      case errNotFound:
        console.debug("router: redirecting to create_profile");
        await router.push({
          name: "create_profile"
        });
        return;

      case errEmailNotVerified:
        console.debug("router: redirecting to verify");
        await router.push({
          name: "verify"
        });
        return;

      default:
        // TODO: show this to the user
        console.error(err);
    }

    return;
  }


  // If there was no error reading the user,
  // then everything is ok.
  console.debug("router: fetched user successfully")
  return;
});

export default router;
