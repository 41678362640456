<template>
  <div class="banana-loading">
    <img :src="require('@/assets/images/banana-dance.gif')" alt="Dancing banana loading animation" />
    <p>Loading…</p>
  </div>
</template>
<style scoped>
.banana-loading {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

img {
  max-width: 150px;
}
</style>
