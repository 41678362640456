import Big, { BigSource } from "big.js";

export default class Decimal {
  // raw: string;
  big: Big;

  constructor(value: BigSource) {
    // if (typeof value === "string") {
    //     this.raw = value;
    // } else {
    //     this.raw = value.toString();
    // }
    this.big = new Big(value);
  }

  equals(y: Decimal): boolean {
    return this.big.eq(y.big);
  }

  add(y: Decimal): Decimal {
    return new Decimal(this.big.plus(y.big));
  }

  sub(y: Decimal): Decimal {
    return new Decimal(this.big.minus(y.big));
  }

  multiply(y: Decimal): Decimal {
    return new Decimal(this.big.mul(y.big));
  }

  // TODO: multiplyMoney

  div(y: Decimal): Decimal {
    return new Decimal(this.big.div(y.big));
  }

  round(dp: number): Decimal {
    return new Decimal(this.big.round(dp));
  }

  toFixed(dp: number): string {
    return this.big.toFixed(dp);
  }

  shift(n: number): Decimal {
    const b = new Big(this.big);
    b.e += n;
    return new Decimal(b);
  }

  toString(): string {
    return this.big.toString();
  }

  toNumber(): number {
    return this.big.toNumber();
  }

  toJSON(): string {
    return this.big.toJSON();
  }

  clone(): Decimal {
    return new Decimal(this.big.valueOf());
  }
}
