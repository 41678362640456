import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = { id: "main-content" }
const _hoisted_2 = { class: "page-content-container" }
const _hoisted_3 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Header = _resolveComponent("Header")!
  const _component_LoadingBanana = _resolveComponent("LoadingBanana")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_Footer = _resolveComponent("Footer")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_Header),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        (_ctx.loading)
          ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
              (_ctx.loading)
                ? (_openBlock(), _createBlock(_component_LoadingBanana, { key: 0 }))
                : _createCommentVNode("", true)
            ]))
          : (_openBlock(), _createBlock(_component_router_view, { key: 1 }))
      ])
    ]),
    _createVNode(_component_Footer)
  ], 64))
}