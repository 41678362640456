import { Decimal, Money } from "@/decimal";
import Currency from "../decimal/currency";
import ExchangeRate from "@/decimal/exchange";

export type JsonObject = { [key: string]: Json };
export type Json = string | number | boolean | null | Json[] | JsonObject;

export function parseString(obj: JsonObject, key: string): string {
  return getValue<string>(obj, key, "string");
}

export function parseBoolean(obj: JsonObject, key: string): boolean {
  return getValue<boolean>(obj, key, "boolean");
}

export function parseNumber(obj: JsonObject, key: string): number {
  return getValue<number>(obj, key, "number");
}

export function parseNullableString(obj: JsonObject, key: string): string | null {
  return getNullableValue<string>(obj, key, "string");
}

export function parseStringOrUndefined(
  obj: JsonObject,
  key: string
): string | undefined {
  return getValueOrUndefined<string>(obj, key, "string");
}

export function parseNullableDecimal(obj: JsonObject, key: string): Decimal | null {
  const str = getNullableValue<string>(obj, key, "string");
  if (str === null) {
    return null;
  }

  return new Decimal(str);
}

export function parseMoney(obj: JsonObject, key: string): Money {
  const str = getValue<string>(obj, key, "string");
  return Money.fromString(str);
}

export function parseNullableMoney(obj: JsonObject, key: string): Money | null {
  const str = getNullableValue<string>(obj, key, "string");
  if (str === null) {
    return null;
  }

  return Money.fromString(str);
}

export function parseCurrency(obj: JsonObject, key: string): Currency {
  const str = getValue<string>(obj, key, "string");
  return Currency.fromString(str);
}

export function parseNullableExchangeRate(
  obj: JsonObject,
  key: string
): ExchangeRate | null {
  const str = getNullableValue<string>(obj, key, "string");
  if (str === null) {
    return null;
  }

  return ExchangeRate.fromString(str);
}

function getValue<T extends Json>(
  obj: JsonObject,
  key: string,
  type: string
): T {
  if (!(key in obj)) {
    throw new Error(`key ${key} not found in object`);
  }

  if (obj[key] === null) {
    throw new Error(`key ${key} is null`);
  }

  if (typeof obj[key] !== type) {
    throw new Error(`unexpected type of property ${key}: ${typeof obj[key]}`);
  }

  return <T>obj[key];
}

function getNullableValue<T extends Json>(
  obj: JsonObject,
  key: string,
  type: string
): T | null {
  if (!(key in obj)) {
    throw new Error(`key ${key} not found in object`);
  }

  if (obj[key] === null) {
    return null;
  }

  if (typeof obj[key] !== type) {
    throw new Error(`unexpected type of property ${key}: ${typeof obj[key]}`);
  }

  return <T>obj[key];
}

function getValueOrUndefined<T extends Json>(
  obj: JsonObject,
  key: string,
  type: string
): T | undefined {
  if (!(key in obj)) {
    return undefined;
  }

  if (obj[key] === null) {
    throw new Error(`key ${key} is null`);
  }

  if (typeof obj[key] !== type) {
    throw new Error(`unexpected type of property ${key}: ${typeof obj[key]}`);
  }

  return <T>obj[key];
}
