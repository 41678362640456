import Currency from "@/decimal/currency";
import Decimal from "@/decimal/decimal";
import Money from "@/decimal/money";

const regex = /^([A-Z]{3})\/([A-Z]{3}) (\d+(?:\.\d+)?)$/;

export default class ExchangeRate {
  base: Currency;
  counter: Currency;
  rate: Decimal;

  constructor(base: Currency, counter: Currency, rate: Decimal) {
    this.base = base;
    this.counter = counter;
    this.rate = rate;
  }

  static fromString(value: string): ExchangeRate {
    const matches = value.match(regex);
    if (matches == null || matches.length < 4) {
      throw new Error("exchange rate is not well-formed");
    }

    const base = Currency.fromString(matches[1]);
    const counter = Currency.fromString(matches[2]);
    const rate = new Decimal(matches[3]);

    return new ExchangeRate(base, counter, rate);
  }

  toBase(target: Currency): ExchangeRate {
    if (target.equals(this.base)) {
      return this;
    }

    if (target.equals(this.counter)) {
      return new ExchangeRate(
        this.counter,
        this.base,
        new Decimal(1).div(this.rate) // Invert the rate
      );
    }

    throw new Error(
      `cannot transform exchange rate ${this.toString()} to base ${target.toString()}`
    );
  }

  multiply(y: Money): Money {
    if (!y.currency.equals(this.base)) {
      throw new Error(
        `cannot multiply currency ${y.currency.toString()} by exchange rate ${
          this.toString
        }`
      );
    }

    return new Money(y.amount.multiply(this.rate), this.counter);
  }

  toString(): string {
    return `${this.base}/${this.counter} ${this.rate.toString()}`;
  }

  toJSON(): string {
    return this.toString();
  }

  clone(): ExchangeRate {
    return new ExchangeRate(this.base, this.counter, this.rate);
  }
}
