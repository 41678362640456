import { createApp } from "vue";
import router from "./router";
import App from "./App.vue";
import { auth } from "./firebase";
import { useStore, pinia } from "@/stores";
import { createPinia } from "pinia";
import { recalculateBalances } from "@/stores/plugins";

const unsubscribe = auth.onIdTokenChanged(async (user) => {
  const app = createApp(App);

  app.use(pinia);
  const store = useStore();

  // TODO: enable this again but fix the unhandled errors
  // recalculateBalances();

  unsubscribe();

  // Give the API token to the API client
  if (user != null) {
    // Refresh the token on app startup for the case where the user
    // has verified their email address in another tab, and therefore
    // needs a new token to successfully make requests to the backend.
    // const token = await user.getIdToken(true);
    // store.setApiToken(token);
  }
  // TODO remove the token when user logs out

  app.use(router);

  app.mount("#app");
});
