import firebase from "firebase/app";
import "firebase/auth";
import "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyAMJlDEZjJXWGfVO9bYRnx_klSPhmu_i8Y",
  authDomain: "banana-split-31163.firebaseapp.com",
  projectId: "banana-split-31163",
  storageBucket: "banana-split-31163.appspot.com",
  messagingSenderId: "642201833621",
  appId: "1:642201833621:web:f96e305441b321c07b0b9c",
  measurementId: "G-78X5SS21QS",
};

firebase.initializeApp(firebaseConfig);
firebase.analytics();

const auth = firebase.auth();

if (process.env.NODE_ENV === "development") {
  // @ts-ignore: this is correct but the type definition is wrong
  // https://github.com/firebase/firebase-tools/issues/2773
  auth.useEmulator("http://localhost:9099", { disableWarnings: true } );
}

// Wrap the signIn method to deal with a bug where try/catch
// doesn't work properly.
// https://stackoverflow.com/a/58783559
async function signInWithEmailAndPassword(email: string, password: string): Promise<firebase.auth.UserCredential> {
  return new Promise((resolve, reject) => {
    auth.signInWithEmailAndPassword(email, password)
      .then((uc) => resolve(uc))
      .catch((err) => reject(err));
  });
}

export { auth, signInWithEmailAndPassword };
