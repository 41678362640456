import Big from "big.js";
import Decimal from "./decimal";
import Money from "./money";
import Currency from "./currency";

// Set this to 16 to match shopspring/decimal
Big.DP = 16;

// This is the default but setting to make it clear that this is on purpose
Big.RM = Big.roundHalfUp;

export { Decimal, Money, Currency };
