import {
  parseString,
  parseStringOrUndefined,
  parseNullableString,
  parseCurrency,
  parseBoolean,
  parseMoney,
  parseNullableExchangeRate,
  parseNullableDecimal,
  parseNullableMoney,
  parseNumber
} from "./helpers";

import type { JsonObject } from "./helpers";

import Currency from "../decimal/currency";
import { Decimal, Money } from "@/decimal";
import ExchangeRate from "@/decimal/exchange";

// ----- Domain objects -----

export interface User {
  id: string;
  name: string;
  email: string;
}

export interface Sheet {
  id: string;
  name: string;
  ownerId: string;
  currency: Currency;
  members: Member[];
  expenses?: Expense[];
  public: boolean;
}

export function apiToSheet(s: JsonObject): Sheet {
  return {
    id: parseString(s, "id"),
    name: parseString(s, "name"),
    ownerId: parseString(s, "owner_id"),
    currency: parseCurrency(s, "currency"),
    members: (s.members as JsonObject[])?.map(apiToMember),
    expenses: (s.expenses as JsonObject[])?.map(apiToExpense),
    public: parseBoolean(s, "public")
  };
}

export interface Member {
  id: string;
  sheetId: string;

  // One of:
  name?: string;
  userId?: string;
}

export function apiToMember(m: JsonObject): Member {
  return {
    id: parseString(m, "id"),
    sheetId: parseString(m, "sheet_id"),
    name: parseStringOrUndefined(m, "name"),
    userId: parseStringOrUndefined(m, "user_id")
  };
}

export interface Expense {
  sheetId: string;
  id: string;
  date: string | null; // TODO: date
  description: string;
  currency: Currency; // TODO: currency
  paidBy: Lender[];
  items: Item[];
}

export function apiToExpense(e: JsonObject): Expense {
  return {
    sheetId: parseString(e, "sheet_id"),
    id: parseString(e, "id"),
    date: parseNullableString(e, "date"),
    description: parseString(e, "description"),
    currency: parseCurrency(e, "currency"),
    paidBy: (e.paid_by as JsonObject[])?.map(apiToLender),
    items: (e.items as JsonObject[])?.map(apiToItem)
  };
}

export function cloneExpense(ex: Expense): Expense {
  return {
    ...ex,
    currency: ex.currency.clone(),
    paidBy: ex.paidBy.map((l) => cloneLender(l)),
    items: ex.items.map((i) => cloneItem(i))
  };
}

export interface Lender {
  memberId: string;
  amount: Money;
  exchangeRate: ExchangeRate | null;
}

export function apiToLender(l: JsonObject): Lender {
  return {
    memberId: parseString(l, "member_id"),
    amount: parseMoney(l, "amount"),
    exchangeRate: parseNullableExchangeRate(l, "exchange_rate")
  };
}

export function cloneLender(l: Lender): Lender {
  return {
    memberId: l.memberId,
    amount: l.amount,
    exchangeRate: l.exchangeRate?.clone() ?? null
  };
}

export interface Item {
  id: string;
  sheetId: string;
  expenseId: string;
  quantity: Decimal | null;
  description: string;
  unitAmount: Money | null;
  subtotal: Money | null;
  tax: Decimal | null;
  total: Money | null;
  paidFor: Array<Borrower>;
}

export function apiToItem(i: JsonObject): Item {
  return {
    id: parseString(i, "id"),
    sheetId: parseString(i, "sheet_id"),
    expenseId: parseString(i, "expense_id"),
    quantity: parseNullableDecimal(i, "quantity"),
    description: parseString(i, "description"),
    unitAmount: parseNullableMoney(i, "unit_amount"),
    subtotal: parseNullableMoney(i, "subtotal"),
    tax: parseNullableDecimal(i, "tax"),
    total: parseNullableMoney(i, "total"),
    paidFor: (i.paid_for as JsonObject[]).map(apiToBorrower)
  };
}

export function cloneItem(i: Item): Item {
  return {
    ...i,
    quantity: i.quantity?.clone() ?? null,
    unitAmount: i.unitAmount?.clone() ?? null,
    subtotal: i.subtotal?.clone() ?? null,
    tax: i.tax?.clone() ?? null,
    total: i.total?.clone() ?? null,
    paidFor: i.paidFor.map((b) => cloneBorrower(b))
  };
}

export interface Borrower {
  memberId: string;
  share: number;
}

export function apiToBorrower(b: JsonObject): Borrower {
  return {
    memberId: parseString(b, "member_id"),
    share: parseNumber(b, "share")
  };
}

export function cloneBorrower(b: Borrower): Borrower {
  return {
    memberId: b.memberId,
    share: b.share
  };
}
