import Big, { BigSource } from "big.js";
import { maxStringLength } from "./constants";
import Currency from "./currency";
import Decimal from "./decimal";
import ExchangeRate from "@/decimal/exchange";

export default class Money {
  amount: Decimal;
  currency: Currency;

  constructor(value: Decimal, currency: Currency) {
    this.amount = value;
    this.currency = currency;
  }

  static fromNumberWithCurrency(value: number, currency: Currency): Money {
    return new Money(new Decimal(value), currency);
  }

  static fromStringWithCurrency(value: string, currency: Currency): Money {
    return new Money(new Decimal(value), currency);
  }

  static fromString(value: string): Money {
    if (value.length < 5) {
      throw new Error(`failed to parse '${value}' as money: too short`);
    }

    if (value.length > maxStringLength) {
      throw new Error(`failed to parse ${value} as money: too long`);
    }

    const c = Currency.fromString(value.substr(0, 3));
    return Money.fromStringWithCurrency(value.substr(4), c);
  }

  equals(y: Money): boolean {
    return this.amount.equals(y.amount);
  }

  isZero(): boolean {
    return this.amount.equals(new Decimal(0));
  }

  add(y: Money): Money {
    if (!this.currency.equals(y.currency)) {
      throw new Error("mismatched currencies");
    }

    return new Money(this.amount.add(y.amount), this.currency);
  }

  sub(y: Money): Money {
    if (!this.currency.equals(y.currency)) {
      throw new Error("mismatched currencies");
    }

    return new Money(this.amount.sub(y.amount), this.currency);
  }

  multiply(y: Decimal): Money {
    return new Money(this.amount.multiply(y), this.currency);
  }

  div(y: Money): Decimal {
    if (!this.currency.equals(y.currency)) {
      throw new Error("mismatched currencies");
    }

    return this.amount.div(y.amount);
  }

  divDifferentCurrency(y: Money): ExchangeRate {
    return new ExchangeRate(
      y.currency,
      this.currency,
      this.amount.div(y.amount)
    );
  }

  round(): Money {
    return new Money(
      this.amount.round(this.currency.minorUnits),
      this.currency
    );
  }

  toString(): string {
    return this.currency.toString() + " " + this.amount.toString();
  }

  toFixed(dp: number): string {
    return this.amount.toFixed(dp);
  }

  toNumber(): number {
    return this.amount.toNumber();
  }

  toJSON(): string {
    return this.toString();
  }

  clone(): Money {
    return new Money(this.amount, this.currency);
  }
}
