import { Expense, Item, Member, Sheet } from "@/api";
import { Decimal, Money } from "@/decimal";
import Currency from "@/decimal/currency";
import { useStore } from "@/stores";
import { watch } from "vue";

export interface Balance {
  lent: Money;
  borrowed: Money;
  repaid: Money;
  received: Money;
  net: Money;
}

function newBalance(currency: Currency): Balance {
  return {
    lent: Money.fromNumberWithCurrency(0, currency),
    borrowed: Money.fromNumberWithCurrency(0, currency),
    repaid: Money.fromNumberWithCurrency(0, currency),
    received: Money.fromNumberWithCurrency(0, currency),
    net: Money.fromNumberWithCurrency(0, currency),
  };
}

export default (): void => {
  const store = useStore();
  watch(store.sheets, (newValue) => {
    for (const sheetId in newValue) {
      const balances = calculateSheetBalances(newValue[sheetId]);
      store.setSheetBalances({
        sheetId: sheetId,
        balances: balances,
      });
    }
  });
};

// export default ({ store }: PiniaPluginContext) => {
//   // called when the store is initialized
//   store.$subscribe((mutation, state) => {
//     console.log(mutation);
//     // switch (mutation.type) {
//     // }
//   });
// };

// function handleSetSheet(store: Store<State>, sheet: Sheet) {
//   const balances = calculateSheetBalances(sheet);
//   store.direct.commit.setSheetBalances({
//     sheetId: sheet.id,
//     balances: balances,
//   });
// }

// Returns a map from member ID to balance
const calculateSheetBalances = (sheet: Sheet): Record<string, Balance> => {
  const balances: Record<string, Balance> = {};

  for (const member of sheet.members) {
    balances[member.id] = newBalance(sheet.currency);
  }

  // If there are no expenses, return nothing. This may be because
  // there are genuinely no expenses, or just because they weren't
  // fetched in the request. Not sure what exactly to do in the latter case.
  if (sheet.expenses === undefined) {
    return balances;
  }

  for (const expense of sheet.expenses) {
    const paidPerMember = paidPerMemberInSheetCurrency(expense, sheet.currency);

    let totalPaid = Money.fromNumberWithCurrency(0, sheet.currency);
    for (const [memberId, amount] of Object.entries(paidPerMember)) {
      if (memberId === "") continue; // New lenders that the user has just added won't necessarily have a memberId

      totalPaid = totalPaid.add(amount);

      balances[memberId].lent = balances[memberId].lent.add(amount);
    }

    const expenseTotal = sumItems(expense);
    if (expenseTotal.isZero()) {
      continue;
    }

    // const totalPaid = sumPaidInCurrency(expense, sheet.currency);

    // E.g. items add up to EUR 6, and the paid_by fields add up to GBP 5.
    // This gives us a rate of 5 / 6 = 0.83.
    const rate = totalPaid.divDifferentCurrency(expenseTotal);

    const expenseBalances = calculateExpenseBorrowedByMember(
      expense,
      sheet.members
    );
    for (const memberId in expenseBalances) {
      // Convert the amounts to the sheet currency
      const borrowed = rate.multiply(expenseBalances[memberId]);

      balances[memberId].borrowed = balances[memberId].borrowed.add(borrowed);
    }
  }

  for (const member of sheet.members) {
    balances[member.id].net = balances[member.id].lent.sub(
      balances[member.id].borrowed
    );
  }

  return balances;
};

function calculateExpenseBorrowedByMember(
  expense: Expense,
  members: Member[]
): Record<string, Money> {
  const amounts: Record<string, Money> = {};

  // Initialise everyone's balance to zero in the expense's currency
  for (const member of members) {
    amounts[member.id] = Money.fromNumberWithCurrency(0, expense.currency);
  }

  for (const item of expense.items) {
    const itemAmounts = calculateItemBorrowedByMember(
      item,
      expense.currency,
      members
    );

    for (const [memberId, amount] of Object.entries(itemAmounts)) {
      amounts[memberId] = amounts[memberId].add(amount);
    }
  }

  return amounts;
}

function calculateItemBorrowedByMember(
  item: Item,
  currency: Currency,
  members: Member[]
): Record<string, Money> {
  const amounts: Record<string, Money> = {};

  // Initialise everyone's balance to zero in the expense's currency
  for (const member of members) {
    amounts[member.id] = Money.fromNumberWithCurrency(0, currency);
  }

  // If we don't have a total for this item, we can't compute
  // borrowed amounts, so return early.
  if (item.total == null) {
    return amounts; // TODO: compute total if necessary
  }

  // Add up the total number of shares that were given out
  const totalShares = sumShares(item);

  // For each borrower, calculate their share of the total.
  for (const b of item.paidFor) {
    const share = new Decimal(b.share);
    amounts[b.memberId] = item.total.multiply(share.div(totalShares));
  }

  return amounts;
}

function sumItems(expense: Expense): Money {
  let sum = Money.fromNumberWithCurrency(0, expense.currency);

  for (const item of expense.items) {
    if (item.total == null) {
      continue; // TODO: not really sure what to do here
    }

    sum = sum.add(item.total);
  }

  return sum;
}

function paidPerMemberInSheetCurrency(
  expense: Expense,
  currency: Currency
): Record<string, Money> {
  const amounts: Record<string, Money> = {};

  for (const lender of expense.paidBy) {
    let amount;

    if (lender.amount.currency.equals(currency)) {
      amount = lender.amount;
    } else {
      if (lender.exchangeRate == null) {
        throw new Error("missing exchange rate");
      }

      const rate = lender.exchangeRate.toBase(lender.amount.currency);

      if (!currency.equals(rate.counter)) {
        throw new Error("unexpected exchange rate");
      }

      amount = rate.multiply(lender.amount);
    }

    const old =
      amounts[lender.memberId] ?? Money.fromNumberWithCurrency(0, currency);

    amounts[lender.memberId] = old.add(amount);
  }

  return amounts;
}

function sumShares(item: Item): Decimal {
  let totalShares = new Decimal(0);

  for (const borrower of item.paidFor) {
    totalShares = totalShares.add(new Decimal(borrower.share));
  }

  return totalShares;
}
